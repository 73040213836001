// src/Router.js
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/home";
// import People from "./pages/people";
// import About from "./pages/about";
// import Contact from "./pages/contact";
// import Recurit from "./pages/recruit";

const AppRouter = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        {/*<Route path="/people" element={<People />} />*/}
        {/*<Route path="/about" element={<About />} />*/}
        {/*<Route path="/contact" element={<Contact />} />*/}
        {/*<Route path="/recruit" element={<Recurit />} />*/}
      </Routes>
    </Router>
  );
};
export default AppRouter;
