import './App.css';
import Router from './Router'
import React from 'react';
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles'; // Import ThemeProvider
import theme from './theme'; // Import your custom theme

function App() {
  return (

  <ThemeProvider theme={theme}>
    <CssBaseline />
    <Router/>
  </ThemeProvider>
  );
}

export default App;
