import React from 'react';
import { Box, List, ListItem, ListItemText, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const Hamburger = ({ state, handleMenu }) => {
  const menuItems = [
    { 
      title: 'Korean BBQ Edinburgh', 
      link: 'https://koreanbbq.kjgroups.co.uk',
      phone: '0131 299 6789'
    },
    { 
      title: 'Korean BBQ Dundee', 
      link: 'https://koreanbbqdundee.kjgroups.co.uk/',
      phone: '0131 299 6789'
    },
    { 
      title: 'Korean Munchies', 
      link: 'https://koreanmunchies.kjgroups.co.uk/',
      phone: '0131 285 3038'
    },
    { 
      title: 'Soul Sushi', 
      link: 'https://soulsushi.kjgroups.co.uk/',
      phone: '0131 667 0200'
    },

  ];


  return (
    <Box
      sx={{
        position: 'fixed',
        top: '0',
        right: state.clicked ? '0' : '-100%',
        width: '100%',
        height: '100vh',
        background: 'rgba(20, 20, 20, 0.98)',
        transition: 'all 0.4s ease-in-out',
        backdropFilter: 'blur(15px)',
        zIndex: 998,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      {/* Close Button */}
      <IconButton
        onClick={handleMenu}
        sx={{
          position: 'absolute',
          top: '20px',
          right: '20px',
          color: '#fff',
          border: '1px solid rgba(255, 255, 255, 0.2)',
          '&:hover': {
            background: 'linear-gradient(135deg, #FF4D4D 0%, #F9CB28 100%)',
          }
        }}
      >
        <CloseIcon />
      </IconButton>

      <List
        sx={{
          height: '85%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          padding: '10px',
          maxWidth: { md: '600px', lg: '700px' },
          margin: '0 auto',
          width: '90%'
        }}
      >
        {menuItems.map((item, index) => (
          <ListItem
            key={index}
            sx={{
              textAlign: 'center',
              opacity: state.clicked ? 1 : 0,
              transform: state.clicked ? 'translateY(0)' : 'translateY(20px)',
              transition: `all 0.4s cubic-bezier(0.4, 0, 0.2, 1) ${index * 0.1}s`,
              flexDirection: 'column',
              gap: '3px',
              margin: '6px 0',
              perspective: '1000px',
            }}
          >
            <Box
              component="a"
              href={item.link}
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                color: '#fff',
                textDecoration: 'none',
                width: '100%',
                padding: {
                  xs: '12px',
                  sm: '15px',
                  md: '18px'
                },
                fontSize: {
                  xs: '1rem',
                  sm: '1.2rem',
                  md: '1.4rem'
                },
                fontWeight: '600',
                letterSpacing: '2px',
                transition: 'all 0.5s ease',
                textAlign: 'center',
                position: 'relative',
                display: 'block',
                background: 'linear-gradient(145deg, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.02))',
                borderRadius: '12px',
                border: '1px solid rgba(255, 255, 255, 0.1)',
                cursor: 'pointer',
                backdropFilter: 'blur(10px)',
                '&:hover': {
                  transform: 'scale(1.02)',
                  background: 'linear-gradient(145deg, rgba(255, 77, 77, 0.1), rgba(249, 203, 40, 0.1))',
                  boxShadow: '0 10px 20px rgba(255, 77, 77, 0.2)',
                  border: '1px solid rgba(255, 77, 77, 0.3)',
                  '& .MuiTypography-root': {
                    background: 'linear-gradient(135deg, #FF4D4D, #F9CB28)',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                  }
                },
                '&::after': {
                  content: '"→"',
                  position: 'absolute',
                  right: {
                    xs: '8px',
                    sm: '12px',
                    md: '15px'
                  },
                  top: '50%',
                  transform: 'translateY(-50%)',
                  opacity: 0,
                  transition: 'all 0.3s ease',
                },
                '&:hover::after': {
                  opacity: 1,
                  right: {
                    xs: '4px',
                    sm: '8px',
                    md: '10px'
                  },
                }
              }}
            >
              <ListItemText 
                primary={item.title}
                secondary={item.phone}
                sx={{
                  textAlign: 'center',
                  '& .MuiTypography-root': {
                    fontSize: {
                      xs: '1rem',
                      sm: '1.2rem',
                      md: '1.4rem'
                    },
                    fontWeight: '600',
                    transition: 'all 0.3s ease',
                  },
                  '& .MuiTypography-secondary': {
                    color: 'rgba(255, 255, 255, 0.7)',
                    fontSize: {
                      xs: '0.7rem',
                      sm: '0.8rem',
                      md: '0.9rem'
                    },
                    marginTop: '3px',
                  }
                }}
              />
            </Box>
          </ListItem>
        ))}
      </List>

    
    </Box>
  );
};

export default Hamburger;
