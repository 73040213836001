// theme.js
import { createTheme } from '@mui/material/styles';

// Define your custom theme using createTheme
const theme = createTheme({
    typography: {
        fontFamily: 'Ropa Sans',
    },
    // Add other theme configurations as needed
});

export default theme;