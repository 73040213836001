import React from "react";
import { Box, Container, Grid, Typography, Link, IconButton, Divider } from "@mui/material";
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';

function Footer() {
  const restaurants = [
    { name: "Korean BBQ Edinburgh", link: "https://koreanbbq.kjgroups.co.uk" },
    { name: "Korean BBQ Dundee", link: "https://koreanbbqdundee.kjgroups.co.uk" },
    { name: "Korean Munchies", link: "https://koreanmunchies.kjgroups.co.uk" },
    { name: "Soul Sushi", link: "https://soulsushi.kjgroups.co.uk" },
  ];


  return (
    <Box
      component="footer"
      sx={{
        background: 'linear-gradient(to bottom, rgba(0,0,0,0.9), rgba(0,0,0,0.95))',
        color: "#fff",
        pt: 8,
        pb: 3,
        position: 'relative',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          height: '1px',
          background: 'linear-gradient(90deg, transparent, rgba(255,77,77,0.5), rgba(249,203,40,0.5), transparent)'
        }
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          {/* Logo and Description */}
          <Grid item xs={12} md={4}>
            <Typography
              variant="h4"
              sx={{
                fontWeight: 700,
                background: 'linear-gradient(135deg, #FF4D4D, #F9CB28)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                mb: 2
              }}
            >
              KJ GROUPS
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: 'rgba(255,255,255,0.7)',
                mb: 3,
                maxWidth: '300px'
              }}
            >
              Bringing authentic Asian cuisine to Scotland with our diverse collection of restaurants.
            </Typography>
          </Grid>

          {/* Our Restaurants */}
          <Grid item xs={12} md={4}>
            <Typography variant="h6" sx={{ mb: 3, color: '#F9CB28' }}>
              Our Restaurants
            </Typography>
            {restaurants.map((restaurant, index) => (
              <Link
                key={index}
                href={restaurant.link}
                target="_blank"
                sx={{
                  display: 'block',
                  color: 'rgba(255,255,255,0.7)',
                  textDecoration: 'none',
                  mb: 1.5,
                  transition: 'all 0.3s ease',
                  '&:hover': {
                    color: '#FF4D4D',
                    transform: 'translateX(5px)'
                  }
                }}
              >
                {restaurant.name}
              </Link>
            ))}
          </Grid>

          {/* Contact Information */}
          <Grid item xs={12} md={4}>
            <Typography variant="h6" sx={{ mb: 3, color: '#F9CB28' }}>
              Contact Us
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <LocationOnIcon sx={{ color: '#FF4D4D' }} />
                <Typography variant="body2" sx={{ color: 'rgba(255,255,255,0.7)' }}>
                  Edinburgh & Dundee, Scotland
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <PhoneIcon sx={{ color: '#FF4D4D' }} />
                <Link 
                  href="tel:+441312996789" 
                  sx={{ 
                    color: 'rgba(255,255,255,0.7)', 
                    textDecoration: 'none',
                    '&:hover': { color: '#FF4D4D' }
                  }}
                >
                  +44 131 299 6789
                </Link>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <EmailIcon sx={{ color: '#FF4D4D' }} />
                <Link 
                  href="mailto:contact@kjgroups.co.uk"
                  sx={{ 
                    color: 'rgba(255,255,255,0.7)', 
                    textDecoration: 'none',
                    '&:hover': { color: '#FF4D4D' }
                  }}
                >
                  contact@kjgroups.co.uk
                </Link>
              </Box>
            </Box>
          </Grid>
        </Grid>

        <Divider sx={{ my: 4, borderColor: 'rgba(255,255,255,0.1)' }} />

        {/* Copyright Section */}
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center',
          flexWrap: 'wrap',
          gap: 2
        }}>
          <Typography variant="body2" sx={{ color: 'rgba(255,255,255,0.7)' }}>
            © {new Date().getFullYear()} KJ Groups. All rights reserved.
          </Typography>
          <Typography variant="body2" sx={{ color: 'rgba(255,255,255,0.7)' }}>
            Developed by{' '}
            <Link 
              href="https://devclubm.com" 
              target="_blank"
              sx={{ 
                color: '#FF4D4D',
                textDecoration: 'none',
                '&:hover': { color: '#F9CB28' }
              }}
            >
              DevClub
            </Link>
          </Typography>
        </Box>
      </Container>
    </Box>
  );
}

export default Footer;
