import React from "react";
import Footer from "../components/footer";
import Hero2 from "../components/hero2";
import Cards from "../components/cards";
import Carousel from "../components/carousel";
import FullPageMenu from "../components/Headerr";

function Home() {
    return (
        <>
            <FullPageMenu />
            <Hero2 />
            <Cards />
            <Carousel />
            <Footer />
        </>
    );
}

export default Home;
