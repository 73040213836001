import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Box, IconButton, Typography, Container } from '@mui/material';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import CollectionsIcon from '@mui/icons-material/Collections';
import img from '../assets/images/hero.jpeg';
import img2 from '../assets/images/3.jpg';
import img3 from '../assets/images/contactbg.jpeg';
import img4 from '../assets/images/1.jpeg';
import img5 from '../assets/images/2.jpg';

function Carousel() {
    const [positionIndexes, setPositionIndexes] = useState([0, 1, 2, 3, 4]);
    const [activeIndex, setActiveIndex] = useState(0);
    const [isHovered, setIsHovered] = useState(false);

    useEffect(() => {
        const interval = setInterval(() => {
            if (!isHovered) {
                handleNext();
            }
        }, 3000);
        return () => clearInterval(interval);
    }, [isHovered]);

    const handleNext = () => {
        setPositionIndexes((prev) => {
            const next = prev.map((item) => (item - 1 + 5) % 5);
            return next;
        });
        setActiveIndex((prev) => (prev + 1) % 5);
    };

    const handlePrev = () => {
        setPositionIndexes((prev) => {
            const next = prev.map((item) => (item + 1) % 5);
            return next;
        });
        setActiveIndex((prev) => (prev - 1 + 5) % 5);
    };

    const images = [img, img2, img3, img4, img5];
    const positions = ['center', 'left1', 'left', 'right', 'right1'];

    const ImageVariants = {
        center: {
            x: '0%',
            scale: 1,
            zIndex: 5,
            opacity: 1,
            filter: 'brightness(100%)',
            transition: { duration: 0.5 }
        },
        left1: {
            x: '-50%',
            scale: 0.8,
            zIndex: 3,
            opacity: 0.8,
            filter: 'brightness(60%)',
            transition: { duration: 0.5 }
        },
        left: {
            x: '-90%',
            scale: 0.6,
            zIndex: 2,
            opacity: 0.6,
            filter: 'brightness(40%)',
            transition: { duration: 0.5 }
        },
        right: {
            x: '90%',
            scale: 0.6,
            zIndex: 2,
            opacity: 0.6,
            filter: 'brightness(40%)',
            transition: { duration: 0.5 }
        },
        right1: {
            x: '50%',
            scale: 0.8,
            zIndex: 3,
            opacity: 0.8,
            filter: 'brightness(60%)',
            transition: { duration: 0.5 }
        }
    };

    const handleDotClick = (clickedIndex) => {
        if (clickedIndex === activeIndex) return;

        const diff = clickedIndex - activeIndex;
        const absValue = Math.abs(diff);
        const shortestPath = absValue > 2 ? 5 - absValue : absValue;

        if (
            (diff > 0 && absValue <= 2) || 
            (diff < 0 && absValue > 2)
        ) {
            for (let i = 0; i < shortestPath; i++) {
                handleNext();
            }
        } else {
            for (let i = 0; i < shortestPath; i++) {
                handlePrev();
            }
        }
    };

    return (
        <Container 
            maxWidth="xl" 
            sx={{ 
                position: 'relative',
                padding: { xs: '0 10px', sm: '0 20px' },
                minHeight: { xs: '400px', sm: '600px' },
                marginBottom: { xs: '80px', sm: '120px' },
            }}
        >
            <Box sx={{ textAlign: 'center', mb: 4, mt: 8 }}>
                <Box 
                    sx={{ 
                        display: 'flex', 
                        alignItems: 'center', 
                        justifyContent: 'center',
                        gap: 2,
                        mb: 2
                    }}
                >
                    <CollectionsIcon 
                        sx={{ 
                            fontSize: { xs: '2rem', sm: '2.5rem', md: '3rem' },
                            background: 'linear-gradient(135deg, #FF4D4D, #F9CB28)',
                            borderRadius: '50%',
                            padding: '8px',
                            color: 'white'
                        }} 
                    />
                    <Typography 
                        variant="h2" 
                        sx={{
                            fontSize: { xs: '2rem', sm: '2.5rem', md: '3rem' },
                            fontWeight: 'bold',
                            background: 'linear-gradient(135deg, #FF4D4D, #F9CB28)',
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: 'transparent',
                            textTransform: 'uppercase',
                            letterSpacing: '2px'
                        }}
                    >
                        Gallery
                    </Typography>
                </Box>
                <Typography 
                    variant="subtitle1" 
                    sx={{ 
                        color: 'rgba(255,255,255,0.8)',
                        fontSize: { xs: '0.9rem', sm: '1rem' },
                        maxWidth: '600px',
                        margin: '0 auto'
                    }}
                >
                    Explore our collection of memorable moments and delicious dishes
                </Typography>
            </Box>

            <Box
                sx={{
                    position: 'relative',
                    width: '100%',
                    height: { xs: '300px', sm: '450px' },
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    overflow: 'hidden',
                    mt: { xs: 4, sm: 6 },
                    '& > *': {
                        position: 'relative'
                    }
                }}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            >
                <AnimatePresence>
                    {images.map((image, index) => (
                        <motion.div
                            key={index}
                            style={{
                                position: 'absolute',
                                width: '100%',
                                maxWidth: '400px',
                                height: '100%',
                                maxHeight: '400px',
                                borderRadius: '20px',
                                overflow: 'hidden',
                                boxShadow: '0 8px 20px rgba(0,0,0,0.4)',
                                cursor: 'pointer'
                            }}
                            initial="center"
                            animate={positions[positionIndexes[index]]}
                            variants={ImageVariants}
                            transition={{ duration: 0.5 }}
                            whileHover={{ scale: positions[positionIndexes[index]] === 'center' ? 1.05 : 1 }}
                        >
                            <img
                                src={image}
                                alt={`carousel-${index}`}
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                }}
                            />
                        </motion.div>
                    ))}
                </AnimatePresence>

                <IconButton
                    onClick={handlePrev}
                    sx={{
                        position: 'absolute',
                        left: { xs: '10px', md: '5%' },
                        top: '50%',
                        transform: 'translateY(-50%)',
                        zIndex: 6,
                        background: 'rgba(255, 255, 255, 0.1)',
                        backdropFilter: 'blur(5px)',
                        padding: '12px',
                        '&:hover': {
                            background: 'linear-gradient(135deg, rgba(255, 77, 77, 0.3), rgba(249, 203, 40, 0.3))',
                            transform: 'translateY(-50%) scale(1.1)',
                        },
                        transition: 'all 0.3s ease',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '45px',
                        height: '45px',
                        border: '1px solid rgba(255, 255, 255, 0.2)',
                        boxShadow: '0 4px 12px rgba(0,0,0,0.15)'
                    }}
                >
                    <KeyboardDoubleArrowLeftIcon 
                        sx={{ 
                            color: 'white', 
                            fontSize: 32,
                        }} 
                    />
                </IconButton>

                <IconButton
                    onClick={handleNext}
                    sx={{
                        position: 'absolute',
                        right: { xs: '10px', md: '5%' },
                        top: '50%',
                        transform: 'translateY(-50%)',
                        zIndex: 6,
                        background: 'rgba(255, 255, 255, 0.1)',
                        backdropFilter: 'blur(5px)',
                        padding: '12px',
                        '&:hover': {
                            background: 'linear-gradient(135deg, rgba(255, 77, 77, 0.3), rgba(249, 203, 40, 0.3))',
                            transform: 'translateY(-50%) scale(1.1)',
                        },
                        transition: 'all 0.3s ease',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '45px',
                        height: '45px',
                        border: '1px solid rgba(255, 255, 255, 0.2)',
                        boxShadow: '0 4px 12px rgba(0,0,0,0.15)'
                    }}
                >
                    <KeyboardDoubleArrowRightIcon 
                        sx={{ 
                            color: 'white', 
                            fontSize: 32,
                        }} 
                    />
                </IconButton>

                
            </Box>
            <Box
                    sx={{
                        position: 'absolute',
                        left: '50%',
                        bottom: { xs: '-30px'},
                        transform: 'translateX(-50%)',
                        display: 'flex',
                        gap: '8px',
                        zIndex: 2,
                        padding: '8px',
                        background: 'rgba(0,0,0,0.3)',
                        borderRadius: '20px',
                        backdropFilter: 'blur(5px)',
                        width: 'auto',
                    }}
                >
                    {images.map((_, index) => (
                        <Box
                            key={index}
                            onClick={() => handleDotClick(index)}
                            sx={{
                                width: { xs: '8px', sm: '10px' },
                                height: { xs: '8px', sm: '10px' },
                                borderRadius: '50%',
                                background: activeIndex === index 
                                    ? 'linear-gradient(135deg, #FF4D4D, #F9CB28)'
                                    : 'rgba(255, 255, 255, 0.5)',
                                cursor: 'pointer',
                                transition: 'all 0.3s ease',
                                '&:hover': {
                                    transform: 'scale(1.2)',
                                    background: activeIndex === index
                                        ? 'linear-gradient(135deg, #FF4D4D, #F9CB28)'
                                        : 'rgba(255, 255, 255, 0.7)'
                                }
                            }}
                        />
                    ))}
                </Box>
        </Container>
    );
}

export default Carousel;